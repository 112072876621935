<template>
    <div class="">
        <el-card>
            <img src="../assets/cloud.png" alt="">
        </el-card>
    </div>
</template>

<script>
export default {
    name: '',
    components: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    created() {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped></style>